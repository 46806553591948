import React, { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger.js';
import Page from '@components/page';
import Socials from '@components/socials';
import ProjectList from '@components/project-list';
import Halftone from '@components/halftone';
import { SEO } from '@components/seo';
import { breakpoints as breakpoints } from '@src/breakpoints';
import easing from '@utilities/easing';
import { theme } from '@styles/theme.css.js';
import * as styles from "./styles.css.js";

gsap.registerPlugin(ScrollTrigger);

export default function Home({pageContext}) {
  // console.log(pageContext);

  const projects = pageContext.projects;
  const projectTags = pageContext.projectTags;
  const misc = pageContext.misc;
  const socials = pageContext.socials;

  const openingBackgroundRef = useRef();
  const halftoneRef = useRef();
  const openingBottomRef = useRef();
  const clarificationRef = useRef();

  const [clarificationShown, setClarificationShown] = useState(false);
  const [windowWidth, setWindowWidth] = useState(null);
  const [windowHeight, setWindowHeight] = useState(null);
  const [halftoneDotSize, setHalftoneDotSize] = useState(16);

  useEffect(() => {
    function onResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      setHalftoneDotSize((() => {
        let divisor;
        if (window.innerWidth > breakpoints.xx) { divisor = 96; }
        else if (window.innerWidth > breakpoints.xl) { divisor = 84; }
        else if (window.innerWidth > breakpoints.lg) { divisor = 72; }
        else if (window.innerWidth > breakpoints.md) { divisor = 60; }
        else if (window.innerWidth > breakpoints.sm) { divisor = 48; }
        else { divisor = 32; }

        return Math.floor(window.innerWidth / divisor);
      })());
    }

    const fn = debounce(onResize, 33);
    window.addEventListener('resize', fn);
    onResize();

    return () => window.removeEventListener('resize', fn);
  }, []);

  useEffect(() => {
    const timelines = [];

    const openingTrigger = {
      scrollTrigger: {
        invalidateOnRefresh: true,
        trigger: openingBottomRef.current,
        start: '0% 85%',
        end: '0% 40%',
        scrub: true,
        markers: false,
        onUpdate: ({progress, direction}) => {
          // console.log(`opening: ${progress}`);
          // console.log(`${idx} - ${progress} (${direction})`);

          openingBackgroundRef.current.style.opacity = easing.easeInOutSin(1 - progress);
        },
        onLeave: () => {
          halftoneRef.current.style.visibility = 'hidden';
        },
        // onLeaveBack: () => {
        //   halftoneRef.current.style.visibility = 'hidden';
        // },
        onEnter: () => {
          halftoneRef.current.style.visibility = 'visible';
        },
        onEnterBack: () => {
          halftoneRef.current.style.visibility = 'visible';
        },
      }
    };

    const openingTimeline = gsap.timeline(openingTrigger);
    timelines.push(openingTimeline);

    return () => {
      timelines.forEach((timeline) => {
        timeline.kill();
      });
    }
  }, []);

  const onClickAsterisk = () => {
    setClarificationShown(!clarificationShown);
  }

  return (
    <Page>

      <div className={styles.openingBackground} ref={openingBackgroundRef} />

      <div ref={halftoneRef}>
      
        <Halftone
          type='video'
          source='/video/waves.mp4'
          targetWidth={windowWidth}
          targetHeight={windowHeight}
          dotSize={halftoneDotSize}
          mode='bw'
          fps={30}
          bwDotColor='rgba(241, 240, 238, 0.75)'
          // bwDotColor='#f1f0ee'
          // bwDotColor='#b5aab5'
          // bwDotColor='rgba(241, 240, 238, 0.5)'
          backgroundColor='rgba(89, 63, 95, 0)'
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      </div>

      <div className={styles.openingTop}>
        <div className={styles.openingText}>
          <h1 className={styles.openingText_xs} dangerouslySetInnerHTML={{ __html: misc.opening_text_xs }} />
          <h1 className={styles.openingText_sm} dangerouslySetInnerHTML={{ __html: misc.opening_text_sm }} />
          <h1 className={styles.openingText_md} dangerouslySetInnerHTML={{ __html: misc.opening_text_md }} />
        </div>
      </div>
      <div className={styles.openingBottom} ref={openingBottomRef}>
        <div className={styles.professional}>
          <div className={styles.professionalWrapper}>
              <h1 className={styles.professional_xs}>
                <span dangerouslySetInnerHTML={{ __html: misc.professional_xs }} /><span className={styles.asterisk} onClick={onClickAsterisk} title='Well, *many* trades...'>*</span>
              </h1>
              <h1 className={styles.professional_sm}>
                <span dangerouslySetInnerHTML={{ __html: misc.professional_sm }} /><span className={styles.asterisk} onClick={onClickAsterisk} title='Well, *many* trades...'>*</span>
              </h1>
              <h1 className={styles.professional_md}>
                <span dangerouslySetInnerHTML={{ __html: misc.professional_md }} /><span className={styles.asterisk} onClick={onClickAsterisk} title='Well, *many* trades...'>*</span>
              </h1>
              <div className={styles.clarification} ref={clarificationRef} style={{'opacity': clarificationShown ? 1 : 0}}>Well, <em>many</em> trades...</div>
          </div>
        </div>
      </div>

      <div className={styles.whoamiWrapper}>

        {/* begin hide this for video screen cap */}

        <div className={styles.whoami}>
          <div className={styles.bio} dangerouslySetInnerHTML={{ __html: misc.bio }} />
          <div className={styles.socialsContainer}>
            { socials ?
              <Socials
                socials={socials}
                theme='light'
                display='block'
              />
            : null }
          </div>
        </div>

      {/* end hide this for video screen cap */}

      </div>

      <div className={styles.pageContent}>
        <ProjectList
          projects={projects}
          tags={projectTags}
        />
      </div>

      <div className={styles.closing}>
        <div className={styles.closingText}>
          <h1><em>Say hello!</em></h1>
        </div>
        <div className={styles.closingInner}>
          { socials ?
            <Socials
              socials={socials}
              theme='dark'
              display='inline'
              style={styles.footerSocials}
            />
          : null }
        </div>
      </div>

    </Page>
  )
}

export const Head = () => (
  <SEO />
)