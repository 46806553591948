import "src/styles/theme.css.js.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/theme.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE42T0U7DMAxF3/kKSwgJpG5KUidtso9BbRmDQenWdRMS4t9xKlaw24dIVR7uPbFvnDT0XTfA1w3AavWom1N/xFYFMNZm07Jhrg6gsvHjuqFdJfHk6IJbOVnoyMNYEJG7GF0dDdpq8pK7NroYXRNdUdkFuC3RN7UIU5Cee3RWc70MoD2FyCmpVsL0AUqfgcsz8OLQFaUwlFE7iuJFszpAv6ure3aKB840v8xfAyommKeEOtslhu5i7Y0gn6/kdCkRkz13SdTLIuWswF6Tiu2vlKJZ6shZPR/GWxL1vtixkMHaaWr/XuE4NQF+LNaTl9AlUQf6UaA6Dx2Xj/QElbrjYk8iKnX45PIpAPbblosDiWs7k88B3Ey80JMexe8fsHWdo+kDAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/styles/fonts.css.js.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/fonts.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE72UwWrCQBCG73mKvUUPqR5qhXgRC4Ue2kOFXssaZ+3AJls2EyQU370xoqAimZphjzvL/Pvtd/jnxhWUGJ2B+o2Uak8l1RZSVTifazs7TreAm29K1eN4vJ+VPktV5e0gHu2vy9G7I7cEjyb5gE1ltX8gMvGwWW5iaBCTr4DqH4iHp0ijc7R1qk6rs2gXzW8TIWmL2R1Er+2iPNANRZNOoDdYY5UHM8QFCuzpqRNrCTkunF0HM8VHCuxq2gkW1BMPR9LR1VPPeoUFv2xYrXZG0z7AJ+F+llVmfUC45cIqsf4gMl4EcPhlwqotCRQZNwJAEl6mMhgyTnrBLJAIfPKpPeqVhZdm+LVtYu81dAQ45P6D4OCiB8iFnSuQP0LTQTYACgAA\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/project-item/styles.css.js.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/project-item/styles.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VXTXPbNhC9+1fg0lqaiCxJiRJFz3Ta5J5Dk3sGIiERMUgwICTZ9fi/FwDBDwCUrHiagwcmuHi7+/Zhl/K/bQtWrKIAvNwBkOOmJvA5BTtCs8cHsVNCdsCVx2mdghNkM8/7FmYN+7Eqm7l8/3u6x6zhXlZgkisM80wgjV7vXu987ShSRjXMc1wdWpuMwLKeBf46ZqhcgMiP4tN5AUJfPgsvw+GlOryjLEfM21HOaZmCsH4CDSU4B+ywgzMzymi+AIEAVMF2XruTneNN63h5wevqulfOYNXUkKGKTzkJxlCxgtrTint7WGIiqL7/iDlH7H4B7r/iEjXgMzqDf2gJK7HVIIb3D92RM8KHgqdgHShaCa6QV+i90A97uwb/i7rsQj8ZZRf5mzY7ADJKKLOLWhmJrydFMbzfmO8rWqHx6+T66xj8CaD4E9xVylLrhrUJGbxtW9XQBnNMqxQwRCDHJ/QwdoArxUiv3TPOeZGCZVA/yceOqe6Z1jDDXJwL/Fg+Ky3G7TtV0z1lon5NBgkSIkligxs4UclPcIdl1e7/ZhgSWT6B4k3XcNXWcFSv0F+K2kwUNoov1ovO7Usqxa5ghlB31+uQqddBCqDECkXGco30ukwBletKCEquseBZrmttv0mBkn2i97faPhSAlemmpexiHoNpbgZ8YDiXkHL1OCrFLkeewDmWVSO1UCPIZ2EkLu+eGRddMRJadCCFrsBaDGHyR7jsPTB6TkHU8vrkaRGtE60ajp5EERkUwDVDnD+b0PUCDA9HIko5oZPPlNMvUhW3XHotDi2NKQ1ZctlclguZW9GCD07E1o40EbsvlsxcUodkCRYDoeHPBHn8uUad4noAgvb2/e6OT3i6ZHfJWfPjKHqx4y5yAr4ix+9KRVbHj0THz8X/yGr5A+be1K3dj7Qq+zsK9OSxr3A7glQEMHs8MHqslFRlnBMjDqoRtx039XOBOer7mO6ZNpg4FDYAwQZ59KjygGYiNthtE3Cg4/ALmvYJMY5FR/YgwQeBKQjre7fb19ursMeE2JxYvd24F8UtDSJ0G69Vy54nv9PeXyXKMRReGUIVgFUOZqUw1CmvZIuZK9++8VV27SuMz9sPrOHMWp+x+r1ptLGNev7HVlttNZ6Mk/xtnSgudkJr5HV9bXy26LPu26/odJ37ybq8vsnuZp38PLtHJ6/YjW0T//b/1GDKKLmpUM6nlPMx1YvzHfUNHRa6L31zzmqdv08I0VSAh3GAYRvg26UOg2j187U+OVka9Cw77+PUXgZFDk3BkWjy4LgOpvOdUH4nCgt1q4XveB/RMyuoaJcpUItJxxrAVG0vjC2Yyf68GEnUMdtMmCWuWdKb6ZQm5+yjw3nmQmXvhEIuFLKhLo/4KfQ3XQ8DtPVsBtxPoBsHezQa7Lf//lRxaPe2kvvRqIXaTsbpbN68ZevAmVfGjYn6+/ofZlEpaWUQAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var association = '_9hrh42c';
export var description = '_9hrh42e';
export var details = '_9hrh421';
export var detailsBottom = '_9hrh42d';
export var detailsTop = {bordered:'_9hrh423 _9hrh422',unbordered:'_9hrh424 _9hrh422'};
export var item = '_9hrh420';
export var projectDescriptionLinkSvg = '_9hrh42g';
export var projectLink = '_9hrh42f';
export var projectTitleLinkSvg = '_9hrh429';
export var recognition = '_9hrh42h';
export var sub = '_9hrh42a';
export var subRole = '_9hrh42b';
export var title_bp_md = '_9hrh428 _9hrh425';
export var title_bp_sm = '_9hrh427 _9hrh425';
export var title_bp_xs = '_9hrh426 _9hrh425';